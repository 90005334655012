import React, { useEffect, useState } from "react";

import Helmet from "react-helmet";

import styled from "@emotion/styled";

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    padding: 0;
    margin: 0;
    border: 0;

    iframe {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        border: 0;
    }
`;

const IFrame = () => {
    const [eventId] = useState("731");
    const [urlPartial, setUrlPartial] = useState("tbc");
    const [type, setType] = useState("");
    const [url, setUrl] = useState("");

    useEffect(() => {
        if (window) {
            const params = new URLSearchParams(window.location.search);

            setUrlPartial(params.get("url-partial") || "tbc");
            setType(params.get("type") || "");

            const commonParameters = ["key", "lang"];
            const getParameters = {};

            commonParameters.forEach((commonParameter) => {
                const value = params.get(commonParameter);
                if (value) {
                    getParameters[commonParameter] = value;
                }
            });

            params.forEach((value, key) => {
                if (key.startsWith("GUEST_")) {
                    getParameters[key] = value;
                }
            });

            let constructedUrl = `https://gwatch.events/${urlPartial}/${eventId}${
                type ? `/${type}` : ""
            }`;

            let firstParameter = true;
            for (const [key, value] of Object.entries(getParameters)) {
                constructedUrl += `${
                    firstParameter ? "?" : "&"
                }${key}=${encodeURIComponent(value)}`;
                firstParameter = false;
            }

            setUrl(constructedUrl);
        }
    }, [eventId, urlPartial, type, window]);

    return (
        <Container>
            <iframe src={url}></iframe>
        </Container>
    );
};

const HundredYearsPage = () => {
    if (typeof window === "undefined") {
        return null;
    }

    return (
        <>
            <Helmet>
                <title>100 Jahre Hoyer - Mitarbeiter</title>
            </Helmet>
            <IFrame />
        </>
    );
};

export default HundredYearsPage;
